
<template>
    <div>
        <validation-observer ref="simpleRules">
            <b-form  ref="myForm">
            <b-row>
                <b-col md="12">
                <b-form-group>
                    <label for="input">Supplement Brand</label>

                    <b-form-input
                    id="input"
                    maxlengh="100"
                    v-model="SupplementBrandValue"
                    placeholder="Supplement Brand"
                    ></b-form-input>
                </b-form-group>
                </b-col>
                <b-col md="12">
                <b-form-group>
                    <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Supplement Name"
                    >
                    <label for="input">Supplement Name</label>
                    <b-form-input
                        id="input"
                        maxlength="100"
                        v-model="SupplementNameValue"
                        placeholder="Supplement name"
                    ></b-form-input>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col md="12">
                <b-form-group>
                    <validation-provider
                    #default="{ errors }"
                    rules="required"
                    name="Supplement Description"
                    >
                    <label for="input">Supplement Description</label>
                    <b-form-textarea
                        id="input"
                        v-model="DescriptionValue"
                        placeholder="Supplement Description"
                    ></b-form-textarea>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col md="12">
                <b-form-group>
                    <label for="input">Comment</label>
                    <b-form-input
                    id="input"
                    v-model="CommentValue"
                    placeholder="Comment"
                    ></b-form-input>
                </b-form-group>
                </b-col>
                <b-col md="4">
                <b-form-group>
                    <label for="input">Supplement Image</label>
                    <validation-provider #default="{ errors }" name="Image">
                    <b-form-file
                        v-model="image"
                        @input="uploadDoc($event, 'image', 'supplement')"
                        placeholder="Image Drop here"
                    ></b-form-file>
                    <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                </b-form-group>
                </b-col>
                <b-col md="12" class="d-flex justify-content-start mt-2">
                <b-button
                    variant="primary"
                    class="mr-1"
                    @click="handleAddRow()"
                    :disabled="flag"
                >
                    {{ ifEdit == true ? "Update" : "Save" }}
                </b-button>
                <b-button type="reset" variant="primary" @click="handleCloseForm">
                    Cancel
                </b-button>
                </b-col>
            </b-row>
            </b-form>
        </validation-observer>
    </div>
  </template>
  <script>
  import {
    BRow,
    BCol,
    BCardText,
    BCardTitle,
    BPagination,
    BButton,
    BCard,
    BForm,
    BTable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BBadge,
    BSpinner,
    BImg,
    BFormTextarea
  } from "bootstrap-vue";
  import vSelect from "vue-select";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import axios from "axios";
  import _ from "lodash";
import { required } from '@validations'

  
  export default {
    components: {
      BFormTextarea,
      BRow,
      BCol,
      BCardText,
      BImg,
      BCardTitle,
      BButton,
      BPagination,
      BCard,
      BForm,
      BTable,
      vSelect,
      BFormFile,
      BFormInput,
      BFormGroup,
      BBadge,
      ValidationProvider,
      BSpinner,
      ValidationObserver,
    },
    props:['selectedItem','handleCloseForm','ifEdit','getSuppleData'],

    data() {
      return {
        required,
        flag: false,
        Addsupple: false,
        required: "",
        accessToken: localStorage.getItem("accessToken"),
        baseApi: process.env.VUE_APP_APIENDPOINT,
        SupplementName: "",
        SupplementBrandValue: "",
        DescriptionValue: "",
        CommentValue: "",
        SupplementNameValue: "",
      };
    },
    watch:{
        'selectedItem':{
            immediate:true,
            handler:'handleEditForm',
            deep:true
        }
    },
    mounted(){
        if(this.ifEdit){
            this.handleEditForm()
        }
    },
    methods: {
      handleAddRow() {
        this.$refs.simpleRules.validate().then((success) => {
          if (success == false) {
            this.$swal({
              title: "Warning",
              timer: 5000,
              text: "Please fill in all fields",
              icon: "warning",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          }
          if (success) {
            this.handleForm();
          }
        });
      },
      async uploadDoc(event, variable, name) {
        this.flag = true;
        if (event) {
          const formData = new FormData();
          formData.append("url", event);
          formData.append("type", "image");
          await axios({
            method: "POST",
            url: `${this.baseApi}attachments/uploadattachment`,
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${this.accessToken}`,
            },
            data: formData,
          }).then(async (response) => {
            this[variable] = response.data.data.url;
            this.flag = false;
          });
        }
      },
      async handleEditForm() {
        console.log(this.selectedItem);
        this.SupplementBrandValue = this.selectedItem.supplementbrand;
        this.SupplementNameValue = this.selectedItem.name;
        this.DescriptionValue = this.selectedItem.supplementdescription;
        this.CommentValue = this.selectedItem.comment;
        this.image = this.selectedItem.image;
        this.$forceUpdate()
        setTimeout(() => {
          const formElement = this.$refs.myForm;
          if (formElement) {
            formElement.scrollIntoView({
              behavior: "smooth",
              block: "start",
            });
          }
        }, 100);
      },
      async handleForm() {
        this.flag=true
        const customerData = {
          name: this.SupplementNameValue,
          supplementbrand: this.SupplementBrandValue,
          supplementdescription: this.DescriptionValue,
          comment: this.CommentValue,
          image: this.image,
        };
        const requestOptions = {
          method: `${this.ifEdit ? "put" : "post"}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${this.accessToken}`,
          },
          url: this.ifEdit
            ? `${this.baseApi}supplement/update/${this.selectedItem.id}`
            : `${this.baseApi}supplement/add`,
          data: JSON.stringify(customerData),
        };
        await axios(requestOptions)
          .then((response) => {
            this.$swal({
              title: "Submitted",
              text: this.ifEdit
                ? " Supplement Updated Successfully"
                : "Supplement Created Successfully",
              icon: "success",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
            this.getSuppleData();
            this.handleCloseForm()
          })
          .catch((error) => {
            this.$swal({
              title: "Error!",
              text: `${error}`,
              icon: "error",
              timer: 5000,
              customClass: {
                confirmButton: "btn btn-primary",
              },
              buttonsStyling: false,
            });
          })
          .finally(()=>{
            this.flag=false

          })
      },
    },
  };
  </script>
  <style lang="scss">
  .text-danger {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  .suppli-img{
    max-height: 300px;
    max-width: 300px
  }
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  
  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
  
   
  