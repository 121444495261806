
<template>
  <div>
    <div class="p-1">
      <b-row>
        <b-col cols="12" class="mb-1">
          <img
            :src="selectedItem.image"
            alt=""
            class="suppli-img"
            v-if="selectedItem.image"
          />
          <img
            v-else
            style="height: 70px; width: 70px"
            src="../../../../../public/no-image.png"
            alt=""
          />
        </b-col>
        <b-col md="6">
          <div class="d-flex flex-column m-1 w-100">
            <strong>Supplement Name:</strong> {{ selectedItem.name }}
          </div>
        </b-col>
        <b-col md="6">
          <div class="d-flex flex-column m-1 w-100">
            <strong>Supplement Brand:</strong>
            {{ selectedItem.supplementbrand }}
          </div>
        </b-col>
        <b-col md="12">
          <div class="d-flex font-weight-bold flex-column m-1 w-100">
            <strong>Description:</strong
            >{{ selectedItem.supplementdescription }}
          </div>
        </b-col>
        <b-col md="6">
          <div class="d-flex flex-column m-1 w-100">
            <strong>Comment:</strong>{{ selectedItem.comment }}
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
  <script>
import {
  BRow,
  BCol,
  BImg,
} from "bootstrap-vue";

export default {
    props:['selectedItem'],
  components: {
    BRow,
    BCol,
    BImg,
  },
};
</script>
<style>

.suppli-img{
    max-height: 300px;
    max-width: 300px
  }
</style>
   
  