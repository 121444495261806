
<template>
  <div>
    <b-card no-body class="m-0">
      <div class="m-1">
        <b-row class="d-flex justify-content-between w-100">
          <!-- <div class="w-100 d-flex justify-content-end mb-2"> -->
          <b-col md="6" class="d-flex mb-3">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :clearable="false"
              v-model="per_page"
              style="height: 20px"
              class="per-page-selector d-inline-block mx-50 w-25"
              :options="['5', '10', '20', '50', '100']"
              @input="changePagination()"
            />
            <!-- Search -->
          </b-col>
          <b-col md="6" class="d-flex mt-1">
            <div class="w-100 d-flex justify-content-end">
              <!-- <div v-if="searchTerm.type == 'dropdown'">
                  <v-select
                    style="width: 200px"
                    placeholder="Search..."
                    :options="getOptions('customers')"
                    :label="searchTerm.displayName"
                    class="d-inline-block mr-1"
                    v-model="search"
                    @input="handleSearch()"
                  />
                </div>
                <div v-else>
                  <b-form-input
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                    v-model="search"
                    @input="handleSearch()"
                  />
                </div> -->
              <!-- <div>
                  <v-select
                    style="width: 200px;"
                    placeholder="Search..."
                    :options="searchTerm.name === 'Category' ? getOptions('category') : staticUsers"
                    :label="searchTerm.displayName"
                    class="d-inline-block mr-1"
                    v-model="search"
                    @input="handleSearch()"
                  />
                </div> -->

              <!-- <div class="ml-1">
                  <v-select
                    style="width: 150px !important"
                    :clearable="false"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :searchable="false"
                    v-model="searchTerm"
                    placeholder="Select"
                    class="per-page-selector d-inline-block mx-50"
                    label="name"
                    :options="searchOptions"
                    @input="changeSearch()"
                  />
                </div> -->
            </div>
          </b-col>
          <!-- </div> -->
        </b-row>
        <section id="card-images" class="container1">
          <b-row
            md="12"
            class="d-flex justify-content-center align-items-center"
          >
            <b-table
              :busy="isLoading"
              ref="refUserListTable"
              class="position-relative"
              :items="data"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="sortBy"
              show-empty
              empty-text="No matching records found"
              :sort-desc.sync="isSortDirDesc"
              @sort-changed="onSortChanged"
            >
              <template #table-busy>
                <div class="text-center text-secondary my-2">
                  <b-spinner class="align-middle"></b-spinner>
                </div>
              </template>
              <template #cell(id)="data">
                <span
                  class="text-primary font-weight-bold"
                  style="font-size: 15px"
                >
                  {{ data.index + 1 }}
                </span>
              </template>
              <!-- <template #thead-top="{ fields }">
                  <tr>
                    <th v-for="(field, index) in fields" :key="index">
                      <template v-if="field.key == 'Action'"> &nbsp; </template>
                      <template v-else>
                        <b-form-input
                          v-model="columnFilters[field.key]"
                          @input="filterTable()"
                          placeholder="search..."
                        />
                      </template>
                    </th>
                  </tr>
                </template> -->

              <template #cell(name)="data">
                <div class="d-flex align-items-center">
                  <span class="mr-1">
                    <template v-if="data.item && data.item.image !== null && data.item.image !== ''">
                      <b-img
                        height="60px"
                        width="60px"
                        class="mr-1 titleName"
                        :src="data.item.image"
                        alt="Image"
                      ></b-img>
                    </template>
                    <template v-else>
                      <span>
                        <img
                          style="height: 40px; width: 40px"
                          src="../../../../../public/no-image.png"
                          alt=""
                        />
                      </span>
                    </template>
                  </span>
                  <span class="mr-1">
                    <span class="mr-1 text-primary font-weight-bolder">
                      {{ data.item.name }}
                    </span>
                  </span>
                </div>
              </template>
              <template #cell(supplementbrand)="data">
                <span class="mr-1">
                  {{ data.item.supplementbrand }}
                </span>
              </template>

              <template #cell(Action)="data">
                <feather-icon
                  class="mr-2"
                  icon="XIcon"
                  size="25"
                  style="cursor: pointer"
                  @click="handleReject(data.item.id)"
                />
                <feather-icon
                  icon="CheckIcon"
                  size="25"
                  style="cursor: pointer"
                  @click="handleApproval('approve', data.item.id)"
                />
                <feather-icon
                  icon="EyeIcon"
                  size="22"
                  class="m-1"
                  @click="openData(data.item)"
                  style="cursor: pointer"
                />
                <feather-icon
                icon="EditIcon"
                size="22"
                class="cursor-pointer mr-1"
                @click="handleEditForm(data.item)"
              />
              </template>
            </b-table>
            <div class="w-100 d-flex justify-content-between p-1">
              <!-- Pagination -->
              <div>
                <span class="text-nowrap">
                  Showing {{ paginationStart }} to {{ paginationEnd }} of
                  {{ paginationTotal }}</span
                >
              </div>
              <b-pagination
                :total-rows="paginationTotal"
                :per-page="per_page"
                v-model="paginationValue"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @input="changePagination()"
              >
                <!-- <div>
                  <button
                    @click="goToPage(paginationValue - 1)"
                    :disabled="paginationValue === 1"
                  >
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </button>
                  <button
                    @click="goToPage(paginationValue + 1)"
                    :disabled="paginationValue * per_page >= paginationTotal"
                  >
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </button>
                </div> -->
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>

            <b-modal
              hide-footer
              top
              no-close-on-backdrop
              size="md"
              v-model="openModel"
            >
            <view-supplement :selectedItem="selectedItem"/>
            </b-modal>

            <b-modal
            class="d-flex justify-content-between"
            no-close-on-backdrop
            size="md"
            v-model="rejectModal"
            title="Reason"
            hide-footer
          >
            <validation-observer ref="reason">
              <b-row>
                <b-col cols="12">
                  <b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      rules="required"
                      name="reason"
                    >
                      <b-form-textarea
                        id="reason"
                        v-model="reason"
                        placeholder="Enter Reason"
                      ></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                  <b-col md="12" class="d-flex justify-content-end mt-2">
                    <b-button
                      variant="primary"
                      class="mr-1 font-weight-bold"
                      @click.prevent="handleReasonSubmit('reject',selectedId)"
                      style="
                        font-size: 15px;
                        font-family: 'Public Sans', sans-serif;
                      "
                      :disabled="flag"
                    >
                      Submit
                    </b-button>
                    <b-button
                      type="reset"
                      class="font-weight-bold"
                      style="
                        font-size: 15px;
                        font-family: 'Public Sans', sans-serif;
                      "
                      variant="primary"
                      @click="handleCancel"
                    >
                      Cancel
                    </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </b-modal>
          <b-modal
          hide-footer
          top
          no-close-on-backdrop
          size="md"
          title="Edit Supplement"
          v-model="addSupplement">
            <Form :selectedItem="selectedItem" :ifEdit="true" :handleCloseForm='handleCloseForm' :getSuppleData="getSupplement"/>
          </b-modal>
          </b-row>
        </section>
      </div>
    </b-card>
  </div>
</template>
  <script>
import BootstrapVue from "bootstrap-vue";
import {
  BRow,
  BCol,
  BCardText,
  BCardTitle,
  BPagination,
  BButton,
  BCard,
  BForm,
  BTable,
  BFormFile,
  BFormInput,
  BFormGroup,
  BBadge,
  BSpinner,
  BImg,
  BTabs,
  BTab,
  BFormTextarea
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "../../../components/axios";
import { required } from "@validations";
import _ from "lodash";
import { quillEditor } from "vue-quill-editor";
import vSelect from "vue-select";
import viewSupplement from './View.vue'
import Form from './Form.vue'

export default {
  components: {
    BRow,
    BTabs,
    BTab,
    vSelect,
    BCol,
    BCardText,
    BImg,
    BCardTitle,
    BButton,
    BPagination,
    BCard,
    BForm,
    BTable,
    BFormFile,
    BFormInput,
    BFormGroup,
    BBadge,
    ValidationProvider,
    BSpinner,
    ValidationObserver,
    quillEditor,
    BFormTextarea,
    viewSupplement,
    Form
  },
  data() {
    return {
      snowOption: {
        theme: "snow",
      },
      flag: false,
      tableColumns: [
        { key: "id", label: "No", sortable: true },
        { key: "name", label: "Supplement Name", sortable: true },
        { key: "supplementbrand", label: "Supplement Brand", sortable: true },
        { key: "Action", label: "Action" },
        // { key: "supplement", label: "Supplement", sortable: true,thStyle: { width: "500px" } },
        // { key: "trainer", label: "Trainer", sortable: true,thStyle: { width: "300px" } },
        // { key: "client", label: "Client", sortable: true,thStyle: { width: "300px" } },
        // { key: "request", label: "Request Date", sortable: true,thStyle: { width: "300px" } },
      ],
      isSortDirDesc: true,
      required: "",
      accessToken: localStorage.getItem("accessToken"),
      baseApi: process.env.VUE_APP_APIENDPOINT,
      selectedId: null,
      sortBy: "",
      orderBy: true ? "ASC" : "DESC",
      clickedRowData: null,
      isSideDataLoading: false,
      openModel: false,
      staticUsers: [],
      id: 0,
      isLoading: false,
      selectedItem: {},
      ifEdit: false,
      paginationValue: 1,
      paginationTotal: 0,
      per_page: 10,
      paginationStart: 0,
      paginationEnd: 0,
      image: "",
      columnFilters: {},
      params: {},
      activeTab: "allCustomers",
      data: [],
      rejectModal:false,
      addSupplement:false
    };
  },
  mounted() {
    this.getSupplement();
  },
  methods: {
    handleCancel(){
      this.selectedId=0;
      this.reason='';
      this.rejectModal=false
    },
    handleEditForm(item) {
      this.addSupplement = true;
      this.ifEdit = true;
      this.selectedItem=item
    },
    handleCloseForm(){
      this.ifEdit=false;
      this.selectedItem={};
      this.addSupplement=false
    },
    handleReject(id){
      this.selectedId = id
      this.reason=''
      this.rejectModal =true
    },
    onSortChanged(e) {
      this.sortBy = e.sortBy;
      this.orderBy = e.sortDesc ? "DESC" : "ASC";
      this.getSupplement();
    },
    handleReasonSubmit(e,id){
      this.$refs.reason.validate().then((success)=>{
        if(success){
            this.handleApproval(e,id)      
        }
      })
    },
    handleApproval(e, id) {
      let confirmation;
      if (e === "approve") {
        confirmation = "Yes, Approve it!";
      } else {
        confirmation = "Yes, Reject it!";
      }
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: confirmation,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.isConfirmed) {
          this.flag=true

          const requestOptions = {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${this.accessToken}`,
            },
            url: `${this.baseApi}supplement/approve-reject`,
            data:{
              reason:this.reason,
              id:id,
              type:e
            }
            // data: JSON.stringify(customerData),
          };
          axios(requestOptions)
            .then((response) => {
              this.$swal({
                title: "Submitted",
                text: response.data.message
                  ? `${response.data.message}`
                  : response.data.success
                  ? `${response.data.success}`
                  : "Video Updated Successfully",
                icon: "success",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
              this.rejectModal=false

              this.getSupplement();
              this.$forceUpdate();
            })
            .catch((error) => {
              if (error.data.message) {
                this.$swal({
                  title: "Error!",
                  text: error.data.message,
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              } else {
                this.$swal({
                  title: "Error!",
                  text: `${error}`,
                  icon: "error",
                  timer: 5000,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  buttonsStyling: false,
                });
              }
            })
            .finally(()=>{
                this.flag=false
            })
        }
      });
    },
    removeHTMLTags(content) {
      return content.replace(/<[^>]+>/g, "");
    },
    filterTable: _.debounce(function () {
      this.params = {};
      for (let key in this.columnFilters) {
        if (
          this.columnFilters[key] &&
          typeof this.columnFilters[key] == "object"
        ) {
          this.params[key] = this.columnFilters[key]
            .map((inner) => inner.id)
            .join(",");
        } else {
          this.params[key] = this.columnFilters[key];
        }
      }
      this.params.page = this.paginationValue;
      this.getSupplement();
    }, 1000),

    changePagination() {
      this.getSupplement();
    },

    goToPage(page) {
      if (
        page >= 1 &&
        page <= Math.ceil(this.paginationTotal / this.per_page)
      ) {
        this.paginationValue = page;
        this.getSupplement();
      }
    },
    async getSupplement() {
      this.isLoading = true;
      let params = {};
      params.page = this.paginationValue;
      params.per_page = this.per_page;
      if (this.search) {
        params.search = this.search.id ? this.search.id : this.search;
        params.type = this.searchTerm.value;
      }
      if (this.sortBy) {
        params.sortBy = this.sortBy;
        params.orderBy = this.orderBy;
      }
      await axios({
        method: "GET",
        url: `${this.baseApi}supplement/get-pending`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${this.accessToken}`,
        },
        params: params,
      })
        .then((json) => {
          this.data = json?.data.supplement;
          this.paginationTotal = json?.data.pagination.total;
          this.paginationStart = json?.data.pagination.current_page;
          this.paginationEnd = json?.data.pagination.per_page;
          this.isLoading = false;
        })
        .catch((error) => console.log(error, "error"))
        .finally(() => {
          this.isLoading = false;
        });
    },

    openData(item) {
      this.openModel = true;
      this.selectedItem = item;
    },

  },
};
</script>
  <style lang="scss">
.text-danger {
  color: red;
  font-size: 12px;
  margin-top: 5px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/quill.scss";
</style>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  
   
    